import {
  LabelHTMLAttributes,
  ParamHTMLAttributes,
  PropsWithChildren,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '~/components/icon';

function Root({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={twMerge('flex w-full flex-col gap-1', className)}>
      {children}
    </div>
  );
}

function Label({
  children,
  className,
  htmlFor,
  ...rest
}: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label
      {...rest}
      htmlFor={htmlFor}
      className={twMerge(
        'bg-transparent text-sm font-medium text-gray-700',
        className,
      )}
    >
      {children}
    </label>
  );
}

function HelpText({
  children,
  className,
  ...rest
}: ParamHTMLAttributes<HTMLParagraphElement>) {
  return (
    <p {...rest} className={twMerge('text-xs text-gray-700', className)}>
      {children}
    </p>
  );
}

function ErrorText({
  children,
  className,
  ...rest
}: ParamHTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      {...rest}
      className={twMerge(
        'flex justify-start gap-1 text-xs text-error',
        className,
      )}
    >
      <Icon icon="ExclamationCircleSolid" className="size-4" /> {children}
    </p>
  );
}
const Field = {
  ErrorText,
  HelpText,
  Label,
  Root,
};

export default Field;
