import { toCurrency } from '~/utils/formatters';
import { makeShippingProtectionClaimLoader } from '~/utils/routing';

export const { loader, useLoaderData: useItemsLoaderData } =
  makeShippingProtectionClaimLoader(({ context }) => {
    const formatPrice = toCurrency(context.order.currencyCode);

    return {
      items: context.order.lineItems
        .filter((item) => !item.isCorsoProduct)
        .map((item) => ({
          ...item,
          unitDisplayPrice: formatPrice(item.unitPrice),
        })),
      selectedItemIds: context.itemIds,
    };
  });
