import { makeShippingProtectionClaimLoader, redirect } from '~/utils/routing';

export const { loader, useLoaderData: useShippingIssueLoaderData } =
  makeShippingProtectionClaimLoader(({ context, params: { store = '' } }) => {
    const {
      order: { shippingClaimReasonOverrides },
    } = context;

    const shippingClaimAction = shippingClaimReasonOverrides?.find(
      (override) => override.reason === context.reason,
    );

    return shippingClaimAction ?
        { shippingClaimAction }
      : redirect(`/${store}`);
  });
