import { GspReasonLabels } from '~/utils/constants';
import { toCurrency } from '~/utils/formatters';
import { makeShippingProtectionClaimLoader, redirect } from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';

export const { loader, useLoaderData: useReviewData } =
  makeShippingProtectionClaimLoader(({ context }) => {
    const { idFromPlatform, currencyCode } = context.order;

    if (!context.reason) {
      return redirect(`../${idFromPlatform}/new`);
    }

    if (!context.itemIds?.length) {
      return redirect(`../${idFromPlatform}/new/${context.reason}/items`);
    }

    if (!context.meta) {
      return redirect(`../${idFromPlatform}/new/${context.reason}/meta`);
    }

    const validAddress = claimAddressSchema.safeParse(context.address);

    if (!validAddress.success) {
      return redirect(`../${context.reason}/new/address/edit`);
    }
    const formatPrice = toCurrency(currencyCode);

    const items = context.order.lineItems
      .filter((item) => context.itemIds?.includes(item.id))
      .map((item) => ({
        ...item,
        unitDisplayPrice: formatPrice(item.unitPrice),
      }));

    return {
      address: validAddress.data,
      items,
      reason: GspReasonLabels[context.reason],
      meta: context.meta,
    };
  });
