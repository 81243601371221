import { ComponentProps, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Address, OrderAddress } from '~/utils/types';
import Input from './form-controls/input';

type AddressField = {
  name: keyof Address;
  label: string;
  defaultValue: string;
  required: boolean;
  type: 'text' | 'tel';
  autoComplete: string;
  description?: string;
  pattern?: string;
  validationMessages?: ComponentProps<typeof Input>['validationMessages'];
};

const getAddressFields = (
  address?: OrderAddress | Address | null,
): Record<keyof Address, AddressField> => ({
  firstName: {
    name: 'firstName',
    label: 'First Name',
    defaultValue: address?.firstName ?? '',
    required: true,
    type: 'text',
    autoComplete: 'given-name',
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    defaultValue: address?.lastName ?? '',
    required: true,
    type: 'text',
    autoComplete: 'family-name',
  },
  line1: {
    name: 'line1',
    label: 'Address',
    defaultValue: address?.line1 ?? '',
    required: true,
    type: 'text',
    autoComplete: 'address-line1',
  },
  line2: {
    name: 'line2',
    label: 'Apt, suite, etc.',
    defaultValue: address?.line2 ?? '',
    required: false,
    type: 'text',
    autoComplete: 'address-line2',
  },
  city: {
    name: 'city',
    label: 'City',
    defaultValue: address?.city ?? '',
    required: true,
    type: 'text',
    autoComplete: 'address-level2',
  },
  stateOrProvinceCode: {
    name: 'stateOrProvinceCode',
    label: 'State or Province Code',
    defaultValue: address?.stateOrProvinceCode ?? '',
    required: false, // ! should this be required??
    type: 'text',
    autoComplete: 'address-level1',
    description: 'ON, NY, NSW etc.',
    pattern: '[A-Za-z]{2,3}',
    validationMessages: {
      valueMissing:
        'Please enter a state or province code (e.g. ON, NY, NSW etc.)',
      patternMismatch:
        'Please enter a valid two or three character state or province code (e.g. ON, NY, NSW etc.)',
    },
  },
  postalCode: {
    name: 'postalCode',
    label: 'Postal Code',
    defaultValue: address?.postalCode ?? '',
    required: true,
    type: 'text',
    autoComplete: 'postal-code',
  },
  countryCode: {
    name: 'countryCode',
    label: 'Country (2 letter code)',
    defaultValue: address?.countryCode ?? '',
    required: true,
    type: 'text',
    autoComplete: 'country',
    description: 'CA, US, etc.',
    pattern: '[A-Za-z]{2}',
    validationMessages: {
      valueMissing: 'Please enter a two character country code (e.g. CA, US)',
      patternMismatch:
        'Please enter a valid two character country code (e.g. CA, US)',
    },
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    defaultValue: address?.phone ?? '',
    required: false,
    type: 'tel',
    autoComplete: 'tel',
  },
});

export default function AddressFields({
  address,
  className,
  pick,
}: {
  address?: Address | OrderAddress | null;
  className?: string;
  pick?: (keyof Address)[];
}) {
  const fields = useMemo(() => {
    const entries = Object.entries(getAddressFields(address));

    return pick ? entries.filter(([key]) => pick.includes(key)) : entries;
  }, [address, pick]);

  return (
    <div
      className={twMerge('grid grid-cols-1 gap-4 sm:grid-cols-2', className)}
    >
      {fields.map(([key, field]) => (
        <Input
          type={field.type}
          required={field.required}
          id={key}
          key={key}
          className={twMerge(field.name === 'line1' && 'sm:col-span-2')}
          name={`address[${field.name}]`}
          label={field.label}
          description={field.description}
          autoComplete={field.autoComplete}
          defaultValue={field.defaultValue}
          pattern={field.pattern}
          validationMessages={field.validationMessages}
        />
      ))}
    </div>
  );
}
