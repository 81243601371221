import api from '~/utils/api';
import { makeRegistrationLoader, redirect } from '~/utils/routing';

const SEARCH_PARAM = 'q';

const { loader, useLoaderData: useRegisterProductData } =
  makeRegistrationLoader(({ context, request }) => {
    const search = new URLSearchParams(new URL(request.url).search);
    const searchTerm = search.get(SEARCH_PARAM);

    const {
      draft,
      settings: {
        theme: {
          identifyProductDetailText,
          identifyProductUrl,
          identifyProductFormPlaceholder,
          identifyProductUrlButtonText,
        },
      },
    } = context;

    // if there is no draft registration, redirect to the lookup page
    if (!draft) return redirect(`..`);

    const productsRequest =
      searchTerm ?
        api.fetchRegistrationProducts({
          params: {
            storefrontId: context.settings.storefrontId,
          },
          query: {
            searchTerm,
            storeSalesChannelName: draft.storeSalesChannel.name,
            estimatedPurchaseDate: draft.estimatedPurchaseDate,
          },
        })
      : Promise.resolve([]);

    return productsRequest.then((products) => ({
      products,
      identifyProductUrl,
      identifyProductFormPlaceholder:
        identifyProductFormPlaceholder ?? 'Search for your product',
      identifyProductUrlButtonText:
        identifyProductUrlButtonText ?? 'Identify Your Product',
      identifyProductDetailText:
        identifyProductDetailText ??
        'If you need help identifying your product, please contact us.',
    }));
  });

export { loader, SEARCH_PARAM, useRegisterProductData };
