/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { GspClaimReason } from '~/utils/constants';
import {
  claimAddressSchema,
  gspOrderSchema,
  mediaAssetSchema,
  shippingClaimSchema,
} from '~/utils/schemas';
import { orderSlice } from './order';

export const shippingProtectionClaimStateSchema = z.object({
  reason: z.nativeEnum(GspClaimReason).nullable(),
  itemIds: z.array(gspOrderSchema.shape.lineItems.element.shape.id),
  meta: z
    .object({
      noteFromCustomer: shippingClaimSchema.shape.noteFromCustomer.optional(),
      assets: z.array(mediaAssetSchema).optional(),
    })
    .nullable(),
  address: claimAddressSchema.nullable(),
});

type ShippingProtectionClaim = z.infer<
  typeof shippingProtectionClaimStateSchema
>;

const initialState: ShippingProtectionClaim = {
  reason: null,
  itemIds: [],
  meta: null,
  address: null,
};

export const shippingProtectionClaimSlice = createSlice({
  name: 'shippingProtectionClaim',
  initialState,
  reducers: {
    setReason: (
      state,
      action: PayloadAction<ShippingProtectionClaim['reason']>,
    ) => {
      state.reason = action.payload;
    },
    setItemIds: (
      state,
      action: PayloadAction<ShippingProtectionClaim['itemIds']>,
    ) => {
      state.itemIds = action.payload;
    },
    setMeta: (
      state,
      action: PayloadAction<ShippingProtectionClaim['meta']>,
    ) => {
      state.meta = action.payload;
    },
    setAddress: (
      state,
      action: PayloadAction<ShippingProtectionClaim['address']>,
    ) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderSlice.actions.setOrder, () => initialState);
  },
});
