import { z } from 'zod';
import api from '~/utils/api';
import { GspClaimReason } from '~/utils/constants';
import { makeShippingProtectionClaimAction, redirect } from '~/utils/routing';
import {
  claimAddressSchema,
  gspOrderSchema,
  mediaAssetSchema,
  shippingClaimSchema,
} from '~/utils/schemas';

const schema = z.object({
  reason: z.nativeEnum(GspClaimReason),
  itemIds: z.array(gspOrderSchema.shape.lineItems.element.shape.id).min(1),
  meta: z.object({
    noteFromCustomer: shippingClaimSchema.shape.noteFromCustomer.optional(),
    assets: z.array(mediaAssetSchema).optional(),
  }),
  address: claimAddressSchema,
});

export const { action, useActionResult: useReviewActionResult } =
  makeShippingProtectionClaimAction(async ({ context }, app) => {
    const result = schema.safeParse(context);
    if (!result.success) {
      return new Error('Invalid shipping claim context', {
        cause: result.error,
      });
    }

    const { reason, address: shippingAddress, meta } = result.data;

    const claim = await api.createProtectionClaim({
      params: { storefrontId: context.order.storefrontId },
      body: {
        reason,
        originalStoreOrder: {
          id: context.order.id,
          lineItems: context.order.lineItems
            .filter((item) => context.itemIds?.includes(item.id))
            .map(({ id, quantity }) => ({
              id,
              quantity,
            })),
        },
        noteFromCustomer: meta?.noteFromCustomer,
        images: meta?.assets?.map(({ name }) => name) ?? [],
        shippingAddress,
      },
    });

    // cache the claim with the order
    app.dispatch(
      app.actions.setOrder({
        ...context.order,
        shippingClaims: [...(context.order.shippingClaims ?? []), claim],
      }),
    );

    return redirect(`../../claim/${claim.id}/new`);
  });
