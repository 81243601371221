import { zfd } from 'zod-form-data';
import { makeShippingProtectionClaimAction, redirect } from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';

const schema = zfd.formData({
  address: claimAddressSchema,
});

export const { action, useActionResult: useEditAddressActionResult } =
  makeShippingProtectionClaimAction(({ formData }, app) => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      return {
        ok: false,
        message: 'Invalid address. Check your address information.',
      };
    }

    app.dispatch(
      app.actions.shippingProtectionClaim.setAddress(result.data.address),
    );

    return redirect('../../review');
  });
