/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { draftRegistrationToCreate } from '~/utils/registration';
import { makeRegistrationAction, redirect } from '~/utils/routing';

const FORM_KEY = 'variants';
const formSchema = zfd.formData({
  [FORM_KEY]: zfd.repeatable(
    z
      .array(
        z.object({
          id: zfd.numeric(),
          quantity: zfd.numeric(z.number().min(1)),
        }),
      )
      .min(1),
  ),
});

const { action, useActionResult } = makeRegistrationAction(
  ({ context, formData }, store) => {
    const {
      draft,
      settings: { storefrontId },
    } = context;

    // if there is no draft registration
    // redirect to the lookup page
    if (!draft) {
      return redirect(`..`);
    }

    const parsedFormData = formSchema.safeParse(formData);
    if (!parsedFormData.success) {
      return {
        ok: false,
        message: 'Invalid form data',
      };
    }

    return api
      .createRegistration({
        params: { storefrontId },
        body: draftRegistrationToCreate(draft, parsedFormData.data[FORM_KEY]),
      })
      .then((registration) => {
        store.dispatch(
          store.actions.registrations.setRegistration(registration),
        );

        return redirect(`../overview`);
      });
  },
);

export { action, FORM_KEY, useActionResult };
