import { redirect, RouteObject } from 'react-router-dom';
import { makeRegistrationLoader } from '~/utils/routing';
import Overview from './overview';
import Product from './products';
import { action } from './register-action';
import Component from './register-component';
import { loader } from './register-loader';

export default {
  id: 'register',
  path: 'register',
  loader: makeRegistrationLoader(({ context }) =>
    context.settings.registrations.isEnabled ?
      {
        ok: true,
      }
    : redirect('..'),
  ).loader,
  children: [
    {
      action,
      loader,
      Component,
      index: true,
    },
    Product,
    Overview,
  ],
} satisfies RouteObject;
