import { z } from 'zod';
import api from '~/utils/api';
import { MiddlewareFunction } from '~/utils/routing';

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  store: z.string().optional(),
});

type AppStore = NonNullable<Parameters<MiddlewareFunction>[1]['app']>;
type State = ReturnType<AppStore['getState']>;

const setOrder = async (
  params: z.infer<typeof paramsSchema>,
  app: AppStore,
) => {
  const { idFromPlatform, store } = params;
  const { settings } = app.getState();

  const order =
    store && settings ?
      await api.fetchCrewOrder({
        params: { storefrontId: settings.storefrontId },
        query: { idFromPlatform },
      })
    : await api.fetchProtectionOrder({ query: { idFromPlatform } });

  app.dispatch(app.actions.setOrder(order));
};

const isValidOrder = (
  params: z.infer<typeof paramsSchema>,
  order: State['order'],
) =>
  !!order &&
  order.idFromPlatform === params.idFromPlatform &&
  (params.store === undefined || params.store === order.storeSlug);

// enable the order to be available to the app shell via the app context
export const middleware: MiddlewareFunction = async ({ params }, ctx) => {
  // TODO figure out the types for the middleware because app gets defined in a higher scope
  const { app } = ctx;

  if (!app) {
    return ctx;
  }

  const validParams = paramsSchema.parse(params);

  // ! ensure that the order is from the store we are on if we are on a branded reorder path and matches the idFromPlatform
  if (!isValidOrder(validParams, app.getState().order)) {
    await setOrder(validParams, app);
  }

  return ctx;
};
