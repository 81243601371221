import deepmerge from '@fastify/deepmerge';
import {
  combineSlices,
  configureStore,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { z } from 'zod';
import { settingsSchema } from '~/utils/schemas';
import { PartialDeep } from '~/utils/types';
import { crewClaimSlice, crewClaimStateSchema } from './slices/crew-claim';
import { orderSlice, orderStateSchema } from './slices/order';
import {
  registrationsSlice,
  registrationsStateSchema,
} from './slices/registrations';
import {
  shippingProtectionClaimSlice,
  shippingProtectionClaimStateSchema,
} from './slices/shipping-protection-claim';

export type AppState = z.infer<typeof stateSchema>;
export type AppStore = ReturnType<typeof createAppStore>;

export const stateSchema = z
  .object({
    settings: settingsSchema.nullable(),
  })
  .extend({
    [orderSlice.reducerPath]: orderStateSchema,
    [registrationsSlice.reducerPath]: registrationsStateSchema,
    [crewClaimSlice.reducerPath]: crewClaimStateSchema,
    [shippingProtectionClaimSlice.reducerPath]:
      shippingProtectionClaimStateSchema,
  });

const settingsSlice = createSlice({
  name: 'settings',
  initialState: null as AppState['settings'],
  reducers: {
    setSettings: (_, action: PayloadAction<AppState['settings']>) =>
      action.payload,
  },
});

export const createAppStore = (initialState?: PartialDeep<AppState>) => {
  const preloadedState = deepmerge()(
    {
      settings: settingsSlice.getInitialState(),
      [orderSlice.reducerPath]: orderSlice.getInitialState(),
      [registrationsSlice.reducerPath]: registrationsSlice.getInitialState(),
      [crewClaimSlice.reducerPath]: crewClaimSlice.getInitialState(),
      [shippingProtectionClaimSlice.reducerPath]:
        shippingProtectionClaimSlice.getInitialState(),
    } satisfies AppState,
    initialState,
  ) as AppState; // todo fix this type assertion

  const store = configureStore({
    preloadedState,
    reducer: combineSlices(
      settingsSlice,
      orderSlice,
      crewClaimSlice,
      registrationsSlice,
      shippingProtectionClaimSlice,
    ),
  });

  return {
    ...store,
    actions: {
      ...orderSlice.actions,
      ...settingsSlice.actions,
      [registrationsSlice.reducerPath]: registrationsSlice.actions,
      [crewClaimSlice.reducerPath]: crewClaimSlice.actions,
      [shippingProtectionClaimSlice.reducerPath]:
        shippingProtectionClaimSlice.actions,
    },
  };
};
