import { RouteObject } from 'react-router-dom';
import Component from './address-component';
import { loader } from './address-loader';
import EditAddress from './edit';

export default {
  path: 'address',
  children: [
    {
      index: true,
      Component,
      loader,
    },
    EditAddress,
  ],
} satisfies RouteObject;
