import { z } from 'zod';
import { formatDate } from '~/utils/formatters';
import { makeShippingProtectionClaimLoader, redirect } from '~/utils/routing';

export enum EntryPoint {
  new = 'new',
  existing = 'existing',
  deepLink = 'deepLink',
}

const schema = z.object({
  claimId: z.coerce.number(),
  entryPoint: z
    .union([z.literal(EntryPoint.new), z.literal(EntryPoint.existing)])
    .optional(),
});

export const { loader, useLoaderData: useProtectionClaimLoaderData } =
  makeShippingProtectionClaimLoader(({ params, context }) => {
    const { claimId, entryPoint } = schema.parse(params);
    const claim = context.order?.shippingClaims?.find((c) => c.id === claimId);
    return claim ?
        {
          id: claim.id,
          status: claim.status,
          createdOn: formatDate(claim.createdOn),
          entryPoint: entryPoint ?? EntryPoint.deepLink,
        }
      : redirect(
          `/${params.store && params.store !== 'reorder' ? params.store : ''} `,
        );
  });
