import type { RouteObject } from 'react-router-dom';
import Spinner from '~/components/spinner';
import { catchallRoute } from '~/utils/routing';
import CrewLookup from './[:store]';
import Component from './app-shell-component';
import { loader } from './app-shell-loader';
import ErrorPage from './error';
import { middleware } from './middleware';
import Reorder from './reorder';
import GspLookup from './root';

export default {
  id: 'appShell',
  HydrateFallback: Spinner,
  handle: {
    middleware,
  },
  loader,
  ErrorBoundary: ErrorPage,
  Component,
  children: [GspLookup, CrewLookup, Reorder, catchallRoute],
} satisfies RouteObject;
