import { RouteObject } from 'react-router-dom';
import Claim from './claim';
import { middleware } from './middleware';
import NewClaim from './new';
import NotProtected from './not-protected';
import Component from './order-overview-component';
import { loader } from './order-overview-loader';

export default {
  path: ':idFromPlatform',
  handle: {
    middleware,
  },

  children: [
    {
      index: true,
      Component,
      loader,
    },
    Claim,
    NewClaim,
    NotProtected,
  ],
} satisfies RouteObject;
