import { CrewUi } from 'corso-types';
import { addBreadcrumb } from '~/utils/observability';
import { makeRegistrationLoader } from '~/utils/routing';

const { loader, useLoaderData: useRegisterData } = makeRegistrationLoader(
  ({ context, request }) => {
    const {
      shouldCollectProofOfPurchase,
      storeSalesChannels,
      shouldCollectAddress,
      customFields,
    } = context.settings.registrations;

    const referrer = new URL(request.url).searchParams.get(
      CrewUi.REF_QUERY_PARAM,
    );

    // Temporary log to debug store sales channels malformation error
    addBreadcrumb({
      message: 'Registration Loader Store Sales Channels',
      level: 'info',
      data: {
        storeSalesChannels,
        defaultValue: context.draft?.storeSalesChannel,
      },
    });

    return {
      customFields,
      shouldCollectProofOfPurchase,
      storeSalesChannels,
      shouldCollectAddress,
      referrer: referrer ?? undefined,
      draft: context.draft,
      uploadFileInfo: {
        storefrontId: context.settings.storefrontId,
        destination: 'crewProofOfPurchase' as const,
      },
    };
  },
);

export { loader, useRegisterData };
