import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { crewOrderSchema, gspOrderSchema } from '~/utils/schemas';

export const orderStateSchema = z
  .union([crewOrderSchema, gspOrderSchema])
  .nullable();
export type OrderState = z.infer<typeof orderStateSchema>;

export const orderSlice = createSlice({
  name: 'order',
  initialState: null as OrderState,
  reducers: {
    setOrder: (_, action: PayloadAction<OrderState>) => action.payload,
  },
});
