import { makeShippingProtectionClaimLoader, redirect } from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';

export const { loader, useLoaderData: useAddressData } =
  makeShippingProtectionClaimLoader(({ context: { address, order } }, app) => {
    const result = claimAddressSchema.safeParse(
      address ?? order.shippingAddress,
    );

    if (!result.success) {
      return redirect(`edit`);
    }

    app.dispatch(app.actions.shippingProtectionClaim.setAddress(result.data));

    return { address: result.data };
  });
