import Disclosure from '~/components/disclosure';
import Divider from '~/components/divider';
import Icon, { CarrierIcon } from '~/components/icon';
import LineItem from '~/components/line-item';
import LinkButton from '~/components/link-button';
import PaginationControls from '~/components/pagination-controls';
import usePagination from '~/components/use-pagination';
import { formatDate, formatDateTimeUtc } from '~/utils/formatters';
import { OrderHubLoaderData } from './order-loader';

type Tracker = OrderHubLoaderData['trackers'][number];
type TrackerDetail = Tracker['details'][number];
type TrackerStatus = TrackerDetail['status'];

const statusLabels = {
  delivered: 'Delivered',
  available_for_pickup: 'Available for Pickup',
  cancelled: 'Cancelled',
  error: 'Error',
  failure: 'Failure',
  in_transit: 'In Transit',
  out_for_delivery: 'Out for Delivery',
  pre_transit: 'Getting Ready To Ship',
  return_to_sender: 'Return to Sender',
  unknown: 'Unknown',
} satisfies Record<TrackerStatus, string>;

function deliveredOnText(
  trackingDetail: TrackerDetail,
  estimatedDeliveryDate: string | null,
) {
  const { status: latestStatus, date: latestStatusDate } = trackingDetail;
  // if latest status is delivered, show the date it was delivered
  if (latestStatus === 'delivered') {
    return formatDateTimeUtc(latestStatusDate);
  }

  // if latest status is not delivered, show the estimated delivery date if we have one
  if (estimatedDeliveryDate) {
    return formatDate(estimatedDeliveryDate);
  }

  if (latestStatus === 'pre_transit') return 'In Progress';

  return 'Unavailable';
}

function statusHeaderText(latestDetailEvent?: TrackerDetail) {
  if (!latestDetailEvent) return 'Awaiting Details';

  return latestDetailEvent.status === 'delivered' ?
      'Delivered On'
    : 'Estimated Delivery';
}

function CarrierDetail({ tracker }: { tracker: Tracker }) {
  const { carrier } = tracker;
  const { name, trackingCode, trackingUrl } = carrier;

  const trackableUrl =
    trackingUrl ?? `https://www.google.com/search?q=${trackingCode}`;

  return (
    <div className="grid grid-cols-[2.4rem_auto] grid-rows-2 gap-x-2">
      <CarrierIcon carrier={name} className="row-span-2 size-10" />

      <span className="text-sm font-medium">{name}</span>
      <LinkButton
        variant="text"
        to={trackableUrl}
        reloadDocument
        target="_blank"
        rel="noreferrer"
        className="w-fit text-sm"
      >
        {trackingCode}
      </LinkButton>
    </div>
  );
}

function Event({ event }: { event: TrackerDetail }) {
  const {
    status,
    message,
    date,
    location: { city, state },
  } = event;

  return (
    <div className="text-sm">
      <div className="font-medium text-gray-800">
        {formatDate(date)}
        {city && state && (
          <span className="uppercase">{` | ${city}, ${state}`}</span>
        )}
      </div>
      <div className="text-gray-500">{`${statusLabels[status]} - ${message}`}</div>
    </div>
  );
}

function HistoryDetail({ events }: { events: TrackerDetail[] }) {
  const [latestEvent, ...history] = events;

  return latestEvent ?
      <Disclosure summary={<Event event={latestEvent} />}>
        <ul className="mt-4 divide-y divide-outline">
          {history.map((trackingDetail) => (
            <li key={trackingDetail.date} className="py-2">
              <Event event={trackingDetail} />
            </li>
          ))}
        </ul>
      </Disclosure>
    : null;
}

function StatusDisplay({ tracker }: { tracker: Tracker }) {
  const {
    estimatedDeliveryDate,
    details: [latestDetailEvent],
    status: currentStatus,
  } = tracker;

  return (
    <div className="text-gray-800">
      <p className="text-xs font-medium uppercase">
        {statusHeaderText(latestDetailEvent)}
      </p>
      <p className="text-2xl font-medium tracking-tight md:text-4xl">
        {latestDetailEvent ?
          deliveredOnText(latestDetailEvent, estimatedDeliveryDate)
        : 'Order Placed'}
      </p>
      <p className="text-sm text-gray-500 md:text-base">
        {latestDetailEvent ?
          statusLabels[currentStatus]
        : statusLabels.pre_transit}
      </p>
    </div>
  );
}

function NoTracker() {
  return (
    <div className="flex items-center gap-2 border-none text-sm font-medium text-gray-800">
      <Icon icon="InfoCircle" className="size-6 shrink-0" />
      Tracking information not available
    </div>
  );
}

function LineItemDisplay({
  lineItems,
}: {
  lineItems: Tracker['lineItemsInShipment'];
}) {
  const totalItems = lineItems.reduce((acc, item) => acc + item.quantity, 0);
  return (
    <Disclosure
      summary={
        <span className="flex items-center gap-2 text-sm font-medium text-gray-800">
          {totalItems} item{totalItems > 1 && 's'} in this shipment
        </span>
      }
    >
      <div className="mt-8 space-y-4">
        {lineItems.map((item) => (
          <LineItem
            key={item.id}
            variant="small"
            name={item.name ?? 'N/A'}
            imgSrc={item.imgUrl}
            quantity={item.quantity}
          />
        ))}
      </div>
    </Disclosure>
  );
}

export default function TrackingDisplay({ trackers }: { trackers: Tracker[] }) {
  const { data: selectedTracker, ...pagination } = usePagination(trackers);

  const multipleShipments = trackers.length > 1;

  return selectedTracker ?
      <section
        className="flex flex-col gap-4"
        data-testid={selectedTracker.idFromPlatform}
      >
        <h3 className="sr-only">Order Tracking</h3>
        {multipleShipments && (
          <div className="flex flex-col items-center justify-center md:flex-row md:justify-between">
            <div className="mb-4 flex items-center gap-2 text-sm font-medium text-gray-800 md:mb-0">
              <Icon icon="InfoCircle" className="size-6 shrink-0" />
              This order contains {trackers.length} shipments
            </div>
            <PaginationControls
              pageCount={pagination.pageCount}
              currentPage={pagination.currentPage}
              onNext={pagination.next}
              onPrevious={pagination.previous}
            />
          </div>
        )}
        <StatusDisplay tracker={selectedTracker} />

        {selectedTracker.details.length > 0 && (
          <>
            <Divider />
            <HistoryDetail events={selectedTracker.details} />
            <Divider />
          </>
        )}
        <CarrierDetail tracker={selectedTracker} />

        {selectedTracker.lineItemsInShipment.length > 0 && (
          <>
            <Divider />
            <LineItemDisplay lineItems={selectedTracker.lineItemsInShipment} />
          </>
        )}
      </section>
    : <NoTracker />;
}
