import api from '~/utils/api';
import { computeCost, sumBy } from '~/utils/compute';
import { formatDate, toCurrency } from '~/utils/formatters';
import { makeShippingProtectionClaimLoader, redirect } from '~/utils/routing';
import { AsyncReturnType } from '~/utils/types';

type Order = AsyncReturnType<typeof api.fetchProtectionOrder>;

const getLatestClaim = (claims: Order['shippingClaims'] = []) => {
  const sorted = claims.sort((a, b) => b.createdOn.localeCompare(a.createdOn));

  return sorted[0];
};

export const { loader, useLoaderData: useOrderOverviewLoaderData } =
  makeShippingProtectionClaimLoader(({ context }) => {
    const { order } = context;
    const latestClaim = getLatestClaim(order.shippingClaims);

    if (latestClaim) {
      return redirect(`claim/${latestClaim.id}/existing`);
    }

    const allowShippingClaimCreate =
      order.wasShippingProtected || order.isUnprotectedShippingClaimPermitted;

    return allowShippingClaimCreate ?
        {
          store: order.storeName,
          orderNo: order.orderNo,
          orderDate: formatDate(order.createdOn),
          price: toCurrency(order.currencyCode)(
            sumBy(computeCost)(order.lineItems),
          ),

          postalCode: order.shippingAddress?.postalCode,
          wasShippingProtected: order.wasShippingProtected,
        }
      : redirect('not-protected');
  });
