import { RouteObject } from 'react-router-dom';
import { catchallRoute } from '~/utils/routing';
import { action } from './gsp-lookup-action';
import Component from './gsp-lookup-component';

export default {
  path: '/',
  children: [
    {
      index: true,
      action,
      Component,
    },
    catchallRoute,
  ],
} satisfies RouteObject;
