/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { ClaimType } from '~/utils/constants';
import {
  claimAddressSchema,
  draftLineItemClaimSchema,
  lineItemClaimSchema,
  shipmentMethodSchema,
  shipmentRateSchema,
} from '~/utils/schemas';

// TODO: would like to separate into return, warranty, and line item claims
export const crewClaimStateSchema = z.object({
  claimType: z.nativeEnum(ClaimType).nullable(),
  draft: draftLineItemClaimSchema.nullable(),
  lineItemClaims: z.array(lineItemClaimSchema),
  address: claimAddressSchema.nullable(),
  toCustomerRate: shipmentRateSchema.nullable(),
  returnMethod: shipmentMethodSchema.nullable(),
});

type CrewClaimState = z.infer<typeof crewClaimStateSchema>;

const initialState: CrewClaimState = {
  claimType: null,
  draft: null,
  lineItemClaims: [],
  address: null,
  toCustomerRate: null,
  returnMethod: null,
};

export const crewClaimSlice = createSlice({
  name: 'crewClaim',
  initialState,
  reducers: {
    setClaimType: (state, action: PayloadAction<ClaimType>) => {
      state.claimType = action.payload;
    },
    setAddress: (state, action: PayloadAction<CrewClaimState['address']>) => {
      state.address = action.payload;
    },
    setDraft: (state, action: PayloadAction<CrewClaimState['draft']>) => {
      state.draft = action.payload;
    },
    clearDraftClaim: (state) => {
      state.draft = null;
    },

    upsertLineItemClaim: (
      state,
      action: PayloadAction<CrewClaimState['lineItemClaims'][number]>,
    ) => {
      const index = state.lineItemClaims.findIndex(
        (claim) => claim.id === action.payload.id,
      );

      if (index !== -1) {
        state.lineItemClaims.splice(index, 1, action.payload);
      } else {
        state.lineItemClaims.push(action.payload);
      }
    },

    removeLineItemClaim: (
      state,
      action: PayloadAction<CrewClaimState['lineItemClaims'][number]['id']>,
    ) => {
      const index = state.lineItemClaims.findIndex(
        (claim) => claim.id === action.payload,
      );

      if (index > -1) {
        state.lineItemClaims.splice(index, 1);
      }
    },

    setReturnMethod: (
      state,
      action: PayloadAction<CrewClaimState['returnMethod']>,
    ) => {
      state.returnMethod = action.payload;
    },

    setToCustomerRate: (
      state,
      action: PayloadAction<CrewClaimState['toCustomerRate']>,
    ) => {
      state.toCustomerRate = action.payload;
    },
  },
});
