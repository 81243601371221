import { ZodSchema } from 'zod';
import { stringToJSONSchema } from './schemas';

/**
 * Call a function and gracefully return `null` and do nothing if it throws.
 * Can easily add a fallback with nullish coalescing.
 */
// ? possibly export or share elsewhere
function tryIt<R>(fn: () => R) {
  try {
    return fn();
  } catch {
    return null;
  }
}

/**
 * A wrapper around any `Storage` object that will gracefully do nothing or fallback if the storage is disabled.
 */
export function gracefulStorage(storage: Storage) {
  return Object.freeze({
    get length() {
      return tryIt(() => storage.length) ?? 0;
    },

    clear() {
      tryIt(() => storage.clear());
    },

    getItem(key: string) {
      return tryIt(() => storage.getItem(key));
    },

    key(index: number) {
      return tryIt(() => storage.key(index));
    },

    removeItem(key: string) {
      tryIt(() => storage.removeItem(key));
    },

    setItem(key: string, value: string) {
      tryIt(() => storage.setItem(key, value));
    },
  } satisfies Storage);
}

/**
 * A simple client to store and retrieve data from sessionStorage
 * Some browsers disable sessionStorage based on security settings
 * This will check if sessionStorage is enabled before trying to use it
 * If it is not enabled it will not do anything
 * */
export const createSessionStorageClient = <T = unknown>(
  key: string,
  schema: ZodSchema<T>,
) => {
  const client = gracefulStorage(sessionStorage);

  return Object.freeze({
    store: (data: T) => {
      client.setItem(key, JSON.stringify(data));
    },

    retrieve: () => {
      const parsed = stringToJSONSchema
        .pipe(schema)
        .safeParse(client.getItem(key));

      return parsed.success ? parsed.data : null;
    },

    clear: () => client.removeItem(key),
  });
};
