import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { makeShippingProtectionClaimAction, redirect } from '~/utils/routing';

const schema = zfd.formData({
  items: zfd.repeatable(z.array(zfd.numeric()).min(1)),
});

export const { action, useActionResult: useItemsActionData } =
  makeShippingProtectionClaimAction(({ formData }, app) => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      return {
        ok: false,
        message: 'Please select the order items affected.',
      };
    }

    app.dispatch(
      app.actions.shippingProtectionClaim.setItemIds(result.data.items),
    );

    return redirect(`../meta`);
  });
