import { GspReasonLabels } from '~/utils/constants';
import { makeShippingProtectionClaimLoader } from '~/utils/routing';

export const { loader, useLoaderData: useReasonLoaderData } =
  makeShippingProtectionClaimLoader(({ context }) => ({
    reasons: context.order.shippingClaimReasons.map((reason) => ({
      value: reason,
      label: GspReasonLabels[reason],
    })),
    selectedReason: context.reason,
  }));
