import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { makeShippingProtectionClaimAction, redirect } from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';

const schema = zfd.formData(
  z.object({
    noteFromCustomer: zfd.text(z.string().optional()),
    assets: zfd.repeatableOfType(stringToJSONSchema.pipe(mediaAssetSchema)),
    requiredImgUploadCount: zfd.numeric(),
  }),
);

export const { action, useActionResult: useMetaActionResult } =
  makeShippingProtectionClaimAction(({ formData }, app) => {
    const results = schema.safeParse(formData);

    if (!results.success) {
      const formInfo = Object.fromEntries([...formData.entries()]) as Record<
        string,
        unknown
      >;

      return new Error('Malformed form data', {
        cause: {
          error: results.error,
          formInfo,
        },
      });
    }

    const { noteFromCustomer, assets, requiredImgUploadCount } = results.data;

    const assetsRequired = requiredImgUploadCount > 0;

    if (assetsRequired && assets.length < requiredImgUploadCount) {
      return {
        ok: false,
        message: `A minimum of ${requiredImgUploadCount} media files are required`,
      };
    }

    app.dispatch(
      app.actions.shippingProtectionClaim.setMeta({
        noteFromCustomer,
        assets,
      }),
    );

    return redirect('../../address');
  });
