import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { makeAction, redirect } from '~/utils/routing';

export enum FormKeys {
  EmailOrPostalCode = 'emailOrPostalCode',
  OrderNo = 'orderNo',
}

const formSchema = zfd.formData(
  z.object({
    [FormKeys.EmailOrPostalCode]: zfd.text(z.string().min(1)),
    [FormKeys.OrderNo]: zfd.text(z.string().min(1)),
  }),
);

export const { action, useActionResult: useOrderLookupActionResult } =
  makeAction(({ formData }, app) => {
    const query = formSchema.parse(formData);

    return api
      .fetchProtectionOrder({ query })
      .then((order) => {
        app.dispatch(app.actions.setOrder(order));

        return redirect(`./reorder/${order.idFromPlatform}`);
      })
      .catch((error) => ({
        ok: false,
        message: error instanceof Error ? error.message : String(error),
      }));
  });
