import { useEffect } from 'react';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { useParams } from '~/utils/routing';

// TODO Flesh this out with better error format and messaging for different error types
export default function ErrorPage() {
  const params = useParams();

  // Remove the splash screen
  useEffect(() => {
    const body = document.getElementById('crew');

    if (!body) {
      throw new Error('Body not found');
    }
    body.classList.add('ready');
  }, []);

  return (
    <main className="width-full min-h-full bg-gray-100 px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl">
        <Page headline="Something went wrong...">
          <Card>
            <p>Please go home and try again.</p>
            <LinkButton
              className="mt-6 w-full sm:w-1/2"
              to={`/${params.store ?? ''}`}
              reloadDocument // force a reload to hopefully get any app updates
            >
              Home
            </LinkButton>
          </Card>
        </Page>
      </div>
    </main>
  );
}
