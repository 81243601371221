import { StatusCodes } from 'http-status-codes';
import {
  ActionFunctionArgs,
  DataStrategyFunction,
  DataStrategyMatch,
  isRouteErrorResponse,
  json,
  LoaderFunctionArgs,
  matchPath,
  PathPattern,
  redirect,
  RouteObject,
  LoaderFunctionArgs as RouterDataFunctionArgs,
  Params as RouterParams,
  useActionData,
  useLoaderData,
  useMatch,
  useNavigation,
  useNavigationType,
  useParams as useRouterParams,
  useRouteError as useRouterRouteError,
} from 'react-router-dom';
import { z } from 'zod';
import { AppState, AppStore } from '~/stores/app';
import { CrewStore } from '~/stores/crew';
import { logError } from './observability';
import { CrewContext, OverrideProperties, Promisable, Simplify } from './types';

type DataFunctionArgs<Context = unknown, Params = RouterParams> = Simplify<
  Omit<RouterDataFunctionArgs, 'context' | 'params'> & {
    context: Context;
    params: RouterDataFunctionArgs['params'] & Params;
  }
>;
type DataFunctionReturn = Response | Promise<Response>;
type DataFunction<
  TArgs extends DataFunctionArgs,
  TReturn extends DataFunctionReturn,
> = (args: TArgs) => TReturn;

type LoaderFunction<Context, Params = RouterParams> = DataFunction<
  DataFunctionArgs<Context, Params>,
  DataFunctionReturn
>;

type ActionFunction<Context, Params = RouterParams> = DataFunction<
  DataFunctionArgs<Context, Params> & { formData: FormData },
  DataFunctionReturn
>;

export const createLoaderDataHook =
  <T extends object>() =>
  () =>
    useLoaderData() as T;

export const createActionResultHook =
  <T extends object>() =>
  () =>
    useActionData() as T | undefined;

export const useIsLoading = () => {
  const navigation = useNavigation();
  const navigationType = useNavigationType();

  return (
    navigation.state === 'submitting' ||
    (navigation.state === 'loading' && navigationType !== 'POP')
  );
};

// ? normalize as error or response error???
export const useRouteError = () => {
  const error = useRouterRouteError();
  if (error instanceof Error) {
    return error;
  }

  if (isRouteErrorResponse(error)) {
    return new Error('Response Error', { cause: { originalError: error } });
  }

  return new Error('Unknown error occurred', {
    cause: { originalError: error },
  });
};

export const useParams = <Params = RouterParams>(schema?: z.Schema<Params>) => {
  const params = useRouterParams();

  return schema ? schema.parse(params) : params;
};

export { json, redirect };

// ? reject or response 400
/**
 * @deprecated
 * new make loader/action functions handle the errors and throws
 */
export const error = (err: Error) => {
  logError(err, err.cause ? { extra: { cause: err.cause } } : undefined);

  return Promise.reject(err);
};

export const crewLoader =
  (fn: LoaderFunction<CrewContext, { store: string }>) =>
  ({ params, ...args }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    return fn({
      ...args,
      params: { ...restParams, store },
      context: CrewStore,
    });
  };

export const crewAction =
  (fn: ActionFunction<CrewContext, { store: string }>) =>
  ({ request, params }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    return request.formData().then((formData) =>
      fn({
        params: { ...restParams, store },
        request,
        formData,
        context: CrewStore,
      }),
    );
  };

export const withDraftClaim =
  <
    TArgs extends DataFunctionArgs<CrewContext, { store: string }>,
    TReturn extends DataFunctionReturn,
  >(
    fn: DataFunction<
      TArgs & { claim: NonNullable<CrewContext['draftClaim']> },
      TReturn
    >,
  ) =>
  (args: TArgs) => {
    const {
      params: { draftId, idFromPlatform, claimType, store },
      context,
    } = args;

    if (!draftId || !idFromPlatform || !claimType || !store) {
      return error(
        new Error(
          'Missing URL parameters. Please provide draft ID, order ID, and claim type.',
          { cause: { params: args.params } },
        ),
      );
    }

    const claim =
      context.draftClaim && context.draftClaim.id === draftId ?
        context.draftClaim
      : context.getLineItemClaim(draftId);

    if (!claim) {
      return redirect(
        `/${store}/order/${idFromPlatform}/${claimType}`,
        StatusCodes.SEE_OTHER,
      );
    }

    context.setDraftClaim(claim);

    return fn({ ...args, claim });
  };

export const useIsAtIndex = () => {
  const { store = '' } = useParams(z.object({ store: z.string().optional() }));
  const pathMatch = useMatch(`/${store}`);

  return Boolean(pathMatch);
};

export const catchallRoute: RouteObject = {
  path: '*',
  loader: ({ params: { store = '' } }) => redirect(`/${store}`),
};
/**
 * Check the path against the current request URL and return a 200 response if it matches.
 * Otherwise, redirect to the base route.
 * This is useful for routes that are not renderable and should not be accessed directly.
 * For example, the path /order will match as /:store,
 * the app and will try to get settings for the store 'order' instead of hitting the catchall route.
 */
export const nonRenderingRouteLoader =
  (path: string | PathPattern<string>) =>
  ({ request, params }: RouterDataFunctionArgs) => {
    const pathMatch = matchPath(path, new URL(request.url).pathname);

    return pathMatch ? json({ ok: true }) : redirect(`/${params.store ?? ''}`);
  };

// Data Strategy Middleware
export type MiddlewareContext = {
  // TODO figure out how to make the app not optionally undefined
  app?: AppStore;
};

const middlewareContextSchema = z.object({ app: z.unknown() });

const middlewareFnSchema = z
  .function()
  .args(
    z.object({
      request: z.instanceof(Request),
      params: z.record(z.string(), z.union([z.string(), z.undefined()])),
    }),
    z.object({ app: z.unknown() }),
  )
  .returns(z.promise(z.object({ app: z.unknown() })));

export type MiddlewareFunction = (
  { request, params }: { request: Request; params: RouterParams },
  context: MiddlewareContext,
) => Promise<MiddlewareContext>;

type MatchWithMiddleware = OverrideProperties<
  DataStrategyMatch,
  {
    route: OverrideProperties<
      DataStrategyMatch['route'],
      { handle: { middleware: MiddlewareFunction } }
    >;
  }
>;

const hasMiddlewareFn = (
  match: DataStrategyMatch,
): match is MatchWithMiddleware =>
  !match?.route?.handle ?
    false
  : z
      .object({
        middleware: middlewareFnSchema,
      })
      .safeParse(match.route.handle).success;
/**
 * Data Strategy to enable middleware and context for data functions.
 * ! Middleware functions run sequentially and can potentially hurt performance. Be judicious.
 * Inspiration (https://reactrouter.com/6.28.2/routers/create-browser-router#middleware)
 */
export const dataStrategy: DataStrategyFunction = async ({
  request,
  params,
  matches,
}) => {
  // Run middleware **sequentially** and let them add data to `context`
  const context: MiddlewareContext = await matches
    .filter(hasMiddlewareFn)
    .reduce<
      Promise<MiddlewareContext>
    >(async (ctx, match) => match.route.handle.middleware({ request, params }, await ctx), Promise.resolve({}));

  // Run loaders in **parallel** with the `context` value
  const results = await Promise.all(
    matches
      .filter((m) => m.shouldLoad)
      .map((match) =>
        // context passed to `handler` will be passed as the 2nd parameter to loader/action
        match
          .resolve((handler) => handler(context))
          .then((result) => [match.route.id, result] as const),
      ),
  );

  return Object.fromEntries(results);
};

type DataFnArgs<
  Context = unknown,
  Params extends RouterParams = RouterParams,
> = {
  request: Request;
  params: Params;
} & (Context extends NonNullable<unknown> ? { context: Context }
: { context?: Context });

type Data = NonNullable<unknown>;
type DataFnValue<T extends Data> = T | Response | Error; // this differs from `DataFunctionValue` of `react-router-dom`, as we omit `null` and include `Error`
type DataFnReturnValue<T extends Data> = Promisable<DataFnValue<T>>;

type LoaderFnArgs<Context = unknown> = DataFnArgs<Context>;
type LoaderFn<Context, T extends Data> = (
  args: LoaderFnArgs<Context>,
  store: AppStore,
) => DataFnReturnValue<T>;

type ActionOkResult = { ok: true };
type ActionErrorResult = {
  ok: false;
  message?: string;
};
export type ActionResult = ActionOkResult | ActionErrorResult;
type ActionFnArgs<Context = unknown> = DataFnArgs<Context> & {
  formData: FormData;
};
type ActionFn<Context, T extends ActionResult = ActionResult> = (
  args: ActionFnArgs<Context>,
  store: AppStore,
) => DataFnReturnValue<T>;

const handleHasStore = (
  handle: unknown,
): handle is Required<MiddlewareContext> =>
  middlewareContextSchema.safeParse(handle).success;

export const makeLoader = <T extends Data>(fn: LoaderFn<unknown, T>) => ({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLoaderData: () => useLoaderData() as T,
  loader: async (args: LoaderFunctionArgs<unknown>, handle: unknown) => {
    if (!handleHasStore(handle)) {
      throw new Error('App store not found in context');
    }

    const result = await fn({ ...args }, handle.app);

    if (result instanceof Error) throw result;
    return result;
  },
});

export const makeAction = <T extends ActionResult>(
  fn: ActionFn<unknown, T>,
) => ({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useActionResult: () => useActionData() as T | undefined,
  action: async (args: ActionFunctionArgs<unknown>, handle: unknown) => {
    if (!handleHasStore(handle)) {
      throw new Error('App store not found in context');
    }

    const result = await fn(
      { ...args, formData: await args.request.formData() },
      handle.app,
    );
    if (result instanceof Error) throw result;
    return result;
  },
});

export type RegistrationCtx = {
  settings: NonNullable<AppState['settings']>;
} & AppState['registrations'];

export const makeRegistrationLoader = <T extends Data>(
  fn: LoaderFn<RegistrationCtx, T>,
) =>
  makeLoader((args, store) => {
    const state = store.getState();

    if (!state.settings) {
      return new Error('Settings required for registration loader');
    }

    const context = {
      settings: state.settings,
      ...state.registrations,
    };

    return fn({ ...args, context }, store);
  });

export const makeRegistrationAction = <T extends ActionResult>(
  fn: ActionFn<RegistrationCtx, T>,
) =>
  makeAction((args, store) => {
    const state = store.getState();

    if (!state.settings) {
      return new Error('Settings required for registration action');
    }

    const context = {
      settings: state.settings,
      ...state.registrations,
    };

    return fn({ ...args, context }, store);
  });

type ShippingProtectionClaimCtx = {
  order: NonNullable<AppState['order']>;
} & AppState['shippingProtectionClaim'];

export const makeShippingProtectionClaimLoader = <T extends Data>(
  fn: LoaderFn<ShippingProtectionClaimCtx, T>,
) =>
  makeLoader(({ params, ...args }, app) => {
    const { order, shippingProtectionClaim } = app.getState();

    if (!order) {
      const { store } = params;
      // filter out 'reorder' from store slug
      return redirect(`/${store && store !== 'reorder' ? store : ''}`);
    }

    const context = {
      order,
      ...shippingProtectionClaim,
    };

    return fn({ ...args, params, context }, app);
  });

export const makeShippingProtectionClaimAction = <T extends ActionResult>(
  fn: ActionFn<ShippingProtectionClaimCtx, T>,
) =>
  makeAction((args, store) => {
    const state = store.getState();

    if (!state.order) {
      return new Error('Order required for shipping protection claim action');
    }

    const context = {
      order: state.order,
      ...state.shippingProtectionClaim,
    };

    return fn({ ...args, context }, store);
  });
