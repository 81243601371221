import { GspClaimReason } from '~/utils/constants';
import { makeShippingProtectionClaimLoader } from '~/utils/routing';

export const { loader, useLoaderData: useMetaLoaderData } =
  makeShippingProtectionClaimLoader(({ context }) => {
    const requiredImgUploadCount =
      (
        context.reason === GspClaimReason.damaged &&
        context.order.isShippingClaimImgRequired
      ) ?
        1
      : 0;

    const uploadFileInfo =
      context.reason === GspClaimReason.damaged ?
        {
          storefrontId: context.order.storefrontId,
          destination: 'gspClaimImg' as const,
        }
      : undefined;

    return {
      uploadFileInfo,
      requiredImgUploadCount,
      ...context.meta,
    };
  });
