import { isAppEmbedded } from '~/utils/isAppEmbedded';
import { makeLoader } from '~/utils/routing';

/**
 * @deprecated
 * TODO: remove this once the crew flow is using app context
 */
export type AppShellOrderData = {
  orderNo: string;
  idFromPlatform: string;
  orderDate: string;
};

export const { loader, useLoaderData: useAppShellData } = makeLoader(
  ({ params: { store } }, app) => {
    const isEmbedded = isAppEmbedded();

    const { order, settings } = app.getState();

    return {
      store,
      isEmbedded,
      localizeKey: settings?.localizeKey,
      footerText: settings?.theme.footerText,
      order,
    };
  },
);
