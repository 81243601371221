import { ClaimType } from '~/utils/constants';
import { isAppEmbedded } from '~/utils/isAppEmbedded';
import { makeRegistrationLoader, redirect } from '~/utils/routing';

const { loader, useLoaderData: useRegisterOverviewData } =
  makeRegistrationLoader(({ context }) => {
    const { registrations, storeUrl } = context.settings;
    const { isStartWarrantyClaimEnabled } = registrations;

    const isEmbedded = isAppEmbedded();

    // the new registration created in the previous action
    const { registration } = context;

    if (!registration) return redirect(`..`);

    if (registration.referrer && isStartWarrantyClaimEnabled)
      return redirect(
        `../../order/${registration.orderIdFromPlatform}/${ClaimType.warranty}`,
      );

    return {
      registration,
      storeUrl,
      isStartWarrantyClaimEnabled,
      isEmbedded,
    };
  });

export { loader, useRegisterOverviewData };
