import { deepEqual } from 'fast-equals';
import { AppState, createAppStore, stateSchema } from '~/stores/app';
import { CrewStore } from '~/stores/crew';
import api from '~/utils/api';
import { MiddlewareFunction } from '~/utils/routing';
import { storeParamSchema } from '~/utils/schemas';
import { createSessionStorageClient } from '~/utils/storage';
import { defaultTheme, setTheme } from '~/utils/theme';

const stateStorage = createSessionStorageClient('corso-app', stateSchema);

const preloadState = (settings: AppState['settings']) => {
  const persistedState = stateStorage.retrieve();
  // ? maybe some more rules here about validation of persisted state

  // if the persisted state is the same as the current settings, return the persisted state otherwise return just the new the settings
  return persistedState && deepEqual(persistedState.settings, settings) ?
      persistedState
    : {
        settings,
      };
};

const persistState = (store: ReturnType<typeof createAppStore>) => () =>
  stateStorage.store(store.getState());

/**
 * Middleware for the app shell route runs before all loaders.
 * Sets the theme and in the future may initialize the store.
 * The context is passed as handler context to all data functions
 * and can be used to share data/state.
 */
export const middleware: MiddlewareFunction = async ({ params }, context) => {
  const parsed = storeParamSchema.safeParse(params);

  //  query is cached so maybe no need to worry about optimization on requery
  const settings =
    parsed.success ?
      await api.fetchSettings({
        params: { slug: parsed.data.store },
      })
    : null;

  setTheme(settings?.theme ?? defaultTheme);

  // still initializing the old store here until completely moved over to new store
  if (settings) {
    CrewStore.init(settings);
  }

  const preloadedState = preloadState(settings);

  // ? should we make this a singleton and only create a new one if the settings don't match?
  const store = createAppStore(preloadedState);

  store.subscribe(persistState(store));

  return { ...context, app: store };
};
