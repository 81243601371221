import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { GspClaimReason } from '~/utils/constants';
import { makeShippingProtectionClaimAction, redirect } from '~/utils/routing';

const schema = zfd.formData({
  reason: zfd.text(z.nativeEnum(GspClaimReason)),
});

export const { action, useActionResult: useReasonActionData } =
  makeShippingProtectionClaimAction(({ context, formData }, app) => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      return {
        ok: false,
        message: 'Please choose one of the reasons.',
      };
    }

    app.dispatch(
      app.actions.shippingProtectionClaim.setReason(result.data.reason),
    );

    const shippingClaimAction =
      context.order.shippingClaimReasonOverrides?.find(
        (override) => override.reason === result.data.reason,
      );

    const url =
      shippingClaimAction ? 'shipping-issue' : (
        `${result.data.reason.toLocaleLowerCase()}/items`
      );

    return redirect(url);
  });
