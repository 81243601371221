/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { draftRegistrationSchema, registrationSchema } from '~/utils/schemas';

export const registrationsStateSchema = z.object({
  draft: draftRegistrationSchema.nullable(),
  registration: registrationSchema.nullable(),
});

export type RegistrationsState = z.infer<typeof registrationsStateSchema>;

const initialState: RegistrationsState = {
  draft: null,
  registration: null,
};

export const registrationsSlice = createSlice({
  name: 'registrations',
  initialState,
  reducers: {
    setDraft: (state, action: PayloadAction<RegistrationsState['draft']>) => {
      state.draft = action.payload;
      state.registration = null;
    },
    setRegistration: (
      state,
      action: PayloadAction<RegistrationsState['registration']>,
    ) => {
      state.draft = null;
      state.registration = action.payload;
    },
  },
});
